export const API_GATEWAY_ENDPOINT =
  process.env.REACT_APP_API_GATEWAY_ENDPOINT || "";
export const API_GATEWAY_PATIENT_ENDPOINT =
  process.env.REACT_APP_API_GATEWAY_PATIENT_ENDPOINT || "";
export const PATIENT_EYES_BUCKET =
  process.env.REACT_APP_PATIENT_EYES_BUCKET || "";
export const RUM_POOL_ID =
  process.env.REACT_APP_RUM_POOL_ID || "";
export const RUM_APPLICATION_ID =
  process.env.REACT_APP_RUM_APPLICATION_ID || "";
  export const ENV =
  process.env.REACT_APP_ENV || "";
export const ADDITIONAL_PATIENT_DOCUMENTS_BUCKET =
  process.env.REACT_APP_ADDITIONAL_PATIENT_DOCUMENTS_BUCKET || "";
export const REACT_APP_MEDICAL_CERTIFICATE_BUCKET =
  process.env.REACT_APP_MEDICAL_CERTIFICATE_BUCKET || "";
export const BASE_URL =
  process.env.REACT_APP_BASE_URL || "";
export const PATIENT_BASE_URL =
  process.env.REACT_APP_PATIENT_BASE_URL || "";
  export const LEDGER_BUCKET =
  process.env.REACT_APP_LEDGER_BUCKET || "";
  // FIXME: stgで真っ白になるため一旦こちらを削除
export const FIREBASE_API_KEY = process.env.REACT_APP_FIREBASE_API_KEY || "";
export const FIREBASE_AUTH_DOMAIN =
  process.env.REACT_APP_FIREBASE_AUTH_DOMAIN || "";
export const FIREBASE_PROJECT_ID =
  process.env.REACT_APP_FIREBASE_PROJECT_ID || "";
export const FIREBASE_STORAGE_BUCKET =
  process.env.REACT_APP_FIREBASE_STORAGE_BUCKET || "";
export const FIREBASE_MESSAGING_SENDER_ID =
  process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID || "";
export const FIREBASE_APP_ID = process.env.REACT_APP_FIREBASE_APP_ID || "";
export const FIREBASE_MEASUREMENT_ID =
  process.env.REACT_APP_FIREBASE_MEASUREMENT_ID || "";
export const FIREBASE_APP_IDENTITY =
  process.env.REACT_APP_FIREBASE_APP_IDENTITY || "";
export const INSURANCE_CARD_BUCKET =
  process.env.REACT_APP_INSURANCE_CARD_BUCKET || "";
