import {lazy, Suspense} from "react";

import {Session} from "util/Session";

import {IconButton, styled} from "@mui/material";
import {Icon, IconNameType} from "components/atoms/icons";
import {BrowserRouter, Routes, Route} from "react-router-dom";
import {ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

import {Progress} from "../components/atoms/Progress";
import {RouteAuthGuard} from "../provider/routerAuthGuard";

const LoginPage = lazy(() => import("./LoginPage"));
const InitialSettingPage = lazy(() => import("./InitialSettingPage"));
const ConsultationListPage = lazy(() => import("./ConsultationListPage"));
const ClinicAccountsPage = lazy(() => import("./ClinicAccountsPage"));
const ClinicAccountDetailPage = lazy(() => import("./ClinicAccountDetailPage"));
const SettingsPage = lazy(() => import("./SettingsPage"));
const ClinicPage = lazy(() => import("./ClinicPage"));
const PatientsListPage = lazy(() => import("./PatientListPage"));
const PaymentItemListPage = lazy(() => import("./PaymentItemListPage"));
const PatientDetailPage = lazy(() => import("./PatientDetailPage"));
const ConsultationPage = lazy(() => import("./ConsultationPage"));
const OnlineConsultationPage = lazy(() => import("./OnlineConsultationPage"));
const NotFoundPage = lazy(() => import("./NotFoundPage"));
const ForbiddenPage = lazy(() => import("./ForbiddenPage"));

const buildBreadCrumb = (arr: { title: string, path?: string }[]) => {
  return arr
}
export const pages = [
  {
    path: "/",
    component: (
      <>
        <Session/>
        <ConsultationListPage/>
      </>
    ),
  },
  {
    path: "/consultation/:id",
    component: (
      <>
        <Session/>
        <ConsultationPage/>
      </>
    ),
    breadCrumb: (name: string) =>
      buildBreadCrumb([
        {title: "診療一覧", path: "/"},
        {title: `診療情報詳細 ${name}`},
      ]),
  },
  {
    path: "/online-consultation/:id",
    component: (
      <>
        <Session/>
        <OnlineConsultationPage/>
      </>
    ),
  },
  {
    path: "/login",
    component: (
      <>
        <Session/>
        <LoginPage/>
      </>
    ),
  },
  {
    path: "/clinicAccounts",
    component: (
      <>
        <Session/>
        <ClinicAccountsPage/>
      </>
    ),
  },
  {
    path: "/clinicAccountDetail/:id",
    component: (
      <>
        <Session/>
        <ClinicAccountDetailPage/>
      </>
    ),
    breadCrumb: (name: string) =>
      buildBreadCrumb([
        {title: "アカウント一覧", path: "/clinicAccounts"},
        {title: `${name}`},
      ]),
  },
  {
    path: "/patients",
    component: (
      <>
        <Session/>
        <PatientsListPage/>
      </>
    ),
  },
  {
    path: "/paymentItemList",
    component: (
      <>
        <Session/>
        <PaymentItemListPage/>
      </>
    ),
  },
  {
    path: "/patients/:id",
    component: (
      <>
        <Session/>
        <PatientDetailPage/>
      </>
    ),
    breadCrumb: (name: string) =>
      buildBreadCrumb([
        {title: "患者一覧", path: "/patients"},
        {title: `${name}`},
      ]),
  },
  {
    path: "/settings",
    component: (
      <>
        <Session/>
        <SettingsPage/>
      </>
    ),
  },
  {
    path: "/clinic",
    component: (
      <>
        <Session/>
        <ClinicPage/>
      </>
    ),
  },
  {
    path: "/initial-setting",
    component: (
      <>
        <Session/>
        <InitialSettingPage/>
      </>
    ),
  },
];

const StyledContainer = styled(ToastContainer)`
    .Toastify__toast--info {
        color: #009957;
        border-radius: 4px;
        border: 1px solid #9CE0C3;
        background: #E5F3ED;
    }

    .Toastify__toast--success {
        color: #009957;
        border-radius: 4px;
        border: 1px solid #9CE0C3;
        background: #E5F3ED;
    }

    .Toastify__toast--error {
        color: #E31717;
        borderRadius: 4px;
        border: 1px solid #E31717;
        background: #FFF3F3;
    }

    .Toastify__close-button {
        alignSelf: none;
    }
`;

type ToastType = {
  [key: string]: {
    color: string;
    iconName?: IconNameType;
  }
}

const toastColor: ToastType = {
  success: {
    color: "#009957",
    iconName: "checkOutline"
  },
  info: {
    color: "#009957",
    iconName: "infoOutline"
  },
  error: {
    color: "#E31717",
    iconName: "error"
  },
  // Figmaでデザインに起こしてもらう
  warning: {
    color: "#E31717",
  },
  default: {
    color: "#fff",
  },
}

export const Router = () => {
  return (
    <BrowserRouter>
      <Suspense fallback={<Progress/>}>
        <StyledContainer
          style={{minWidth: "500px"}}
          position="top-center"
          autoClose={3000}
          hideProgressBar
          newestOnTop={false}
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          icon={({type}) => (
            toastColor[type].iconName &&
            <Icon
                iconName={toastColor[type].iconName as IconNameType}
                size={24}
                color={toastColor[type].color}
            />
          )}
          closeButton={({type}) => (
            <IconButton>
              <Icon
                iconName="close"
                size={20}
                color={toastColor[type].color}
              />
            </IconButton>
          )}
        />
        <Routes>
          <>
            {pages.map((item, index) => (
              <Route
                key={index}
                path={item.path}
                element={<RouteAuthGuard component={item.component}/>}
              />
            ))}
            <Route path="/forbidden" element={<RouteAuthGuard component={<ForbiddenPage/>}/>}/>
            <Route path="*" element={<RouteAuthGuard component={<NotFoundPage/>}/>}/>
          </>
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
};
